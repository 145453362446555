import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 28px;
  text-align: center;
  margin-bottom: ${(props) => props.marginbottom};
  font-weight: 700;
  color: #2d2d2f;
  // color: #133752;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => (props.wrap ? props.wrap : 'initial')};
  align-items: ${(props) => props.alignitems || 'center'};
  justify-content: ${(props) => props.justifycontent || 'center'};
  margin: ${(props) => props.margin};
  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  }
`;

export const Button = styled.button`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  border-radius: 5px;
  border: solid 2px #133752;
  height: 50px;
  font-size: 16px;
  transition: 0.4s;
  cursor: pointer;
  color: #2d2d2f;
  ${(props) =>
    props.form &&
    css`
      width: 50%;
      padding: 0;
    `}
`;

const FormWrapper = styled.div`
  width: 600px;
  height: auto;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08);
  padding-top: 40px;
  border: 3px solid #053d75;
  padding-bottom: 48px;
  margin-bottom: 60px;
  background-color: #ffffff;
  @media (max-width: 992px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: 780px) {
    width: 95%;
    height: auto;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    border: none;
    padding-top: 0px;
    box-shadow: none;
  }
`;

const Form = styled.form`
  width: 85%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 780px) {
    width: 85%;
  }
  @media (max-width: 600px) {
    width: 95%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 5px;
  @media (max-width: 750px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const Input = styled.input`
  width: calc(100% - 10px);
  height: 50px;
  border-radius: 2px;
  border: ${(props) => (props.error ? 'solid 1px red' : 'solid 1px #c4c9d0')};
  background-color: #ffffff;
  outline: none;
  padding: 0 16px;
  margin-top: 12px;
  transition: 250ms;
  &:focus {
    border: solid 1px #blue;
  }
  @media (max-width: 500px) {
    width: calc(100% - 15px);
  }
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: black;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 5px;
`;

export const Textarea = styled.textarea`
  width: calc(100% - 10px);
  height: 115px;
  border-radius: 2px;
  border: ${(props) => (props.error ? 'solid 1px red' : 'solid 1px #c4c9d0')};
  background-color: #ffffff;
  outline: none;
  padding: 10px 16px;
  margin-top: 12px;
  transition: 250ms;
  &:focus {
    border: solid 1px blue;
  }
  @media (max-width: 500px) {
    width: calc(100% - 15px);
  }
`;

function ContactForm() {
  return (
    <Wrapper id="contact">
      <FormWrapper>
        <Form
          name="contact"
          method="POST"
          data-netlify="false"
          data-netlify-honeypot="bot-field"
          action="https://getform.io/f/f81f9e3e-bab5-49a9-b43a-fea85c9ba497"
        >
          <Title marginbottom="20px">Send us a message!</Title>
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label htmlFor="bot-field">
              Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <Grid>
            <Label>
              First Name
              <Input placeholder="First Name" name="firstName" required />
            </Label>
            <Label>
              Last Name
              <Input placeholder="Last Name" name="lastName" required />
            </Label>
            <Label>
              Email
              <Input placeholder="Email" name="email" type="email" required />
            </Label>
            <Label>
              Phone
              <Input placeholder="Phone" name="phone" />
            </Label>
            <Label>
              Business Name
              <Input placeholder="Business Name" name="businessName" />
            </Label>
          </Grid>
          <Label>
            Message
            <Textarea placeholder="Message" name="message" />
          </Label>
          <Row>
            <Button
              form
              type="submit"
              className="cta-btn cta-btn--contact"
              style={{ color: '#000' }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </FormWrapper>
    </Wrapper>
  );
}
export default ContactForm;
