import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { headData } from '../mock/data';
import SEO from '../components/SEO/SEO';
import Layout from '../components/Layout/Layout.jsx';
import Title from '../components/Title/Title';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { Container, Row, Col } from 'react-bootstrap';
import ContactForm from '../components/Contact/FormContact.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const StyledTitle = styled(Title)`
  font-size: 18px;
  margin-top: 5rem;
`;

const StyledRow = styled(Col)`
  margin-top: 5rem;
`;

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 95%;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  // color: #053d75;
`;

const Icons = styled.i`
  color: #053d75;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const ContactPage = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const { image } = headData;
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    setLoading(false);
  }, []);

  if (loading) return null;
  return (
    <>
      <Helmet lang="en" />
      <SEO
        title="Iffert Media | Contact Us For Social Media Marketing & Advertising Services"
        siteUrl="https://www.iffertmedia.com/contact"
        image={image}
        description="Looking to grow your company through social media marketing & advertising? Iffert Media is a Nashville based marketing agency helping you grow your business!"
      />
      <Layout buttoncolor border backgroundcolor color>
        <Title display fontsize title="Contact Us" />
        <ContactRow>
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
            <SocialCol>
              <Icons className="fa fa-phone fa-4x" aria-hidden="true" />
              <p>(315) 725-4354</p>
            </SocialCol>
            <SocialCol>
              <Icons className="fa fa-envelope fa-4x" aria-hidden="true" />
              <p>cory@iffertmarketing.com</p>
            </SocialCol>
            <SocialCol>
              <Row>
                <a href="https://www.facebook.com/iffertmedia/">
                  <Icons className="fa fa-facebook fa-2x" aria-hidden="true" />
                </a>
                <a href="https://www.instagram.com/iffertmedia/" style={{ marginLeft: '20px' }}>
                  <Icons className="fa fa-instagram fa-2x" aria-hidden="true" />
                </a>
              </Row>
              <p>Follow us</p>
            </SocialCol>
          </Fade>
        </ContactRow>
        <StyledRow className="contact-wrapper">
          <Fade bottom duration={1000} delay={600} distance="30px">
            <SocialCol>
              <ContactForm />
            </SocialCol>
          </Fade>
        </StyledRow>
      </Layout>
    </>
  );
};

export default ContactPage;

// <iframe
//                 src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fiffertmedia%2F&tabs=timeline&width=400px&height=500px&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
//                 className="iframe"
//                 scrolling="no"
//                 frameborder="0"
//                 allowTransparency="true"
//                 allow="encrypted-media"
//               ></iframe>
